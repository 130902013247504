<template>
  <div class="content">
    <Breadcrumb :data="nav" :is$t="true" />
    <Box v-loading="loading" class="pane">
      <div slot="header" class="pane-header">
        <div class="title">{{ detail.title | textFilter }}</div>
      </div>
      <div class="pane-body">
        <img :src="detail.picture" alt="" />
        <div class="ql-editor" v-html="detail.description"></div>
      </div>
      <div class="pane-group">
        <h5 class="title">{{ $t('3baab4d') }}</h5>
        <div class="desc" style="line-height: 34px">
          <div>
            <span>{{ $t('55e02a1') }}</span>
            <em>{{ detail.date | textFilter }}</em>
          </div>
          <div>
            <span>{{ $t('c92c53c') }}</span>
            <em>{{ detail.startTime }} - {{ detail.endTime }}</em>
          </div>
          <div>
            <span>{{ $t('15f5be0') }}</span>
            <em>{{ detail.address | textFilter }}</em>
          </div>
          <div>
            <span>{{ $t('6b310d6') }}</span>
            <em>{{ detail.organizer | textFilter }}</em>
          </div>
          <div>
            <span>{{ $t('f73fdbe') }}</span>
            <em>{{ detail.lector | textFilter }}</em>
          </div>
        </div>
      </div>
    </Box>

    <Box v-loading="loading" :title="autoPrefix('5680a38')">
      <el-form ref="submitForm" :inline="true" :model="form" class="form" :rules="rules" label-width="82px">
        <el-row>
          <el-col :span="8" class="fix-first">
            <el-form-item :label="autoPrefix('8630098')" prop="name">
              <el-input v-model="form.name" :placeholder="autoPrefix('97ea28f')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="autoPrefix('46da118')" prop="phone">
              <el-input v-model="form.phone" :placeholder="autoPrefix('18b2b78')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="autoPrefix('fafa54b')">
              <el-input v-model="form.organization" :placeholder="autoPrefix('520f308')" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="fix-row">
          <el-col :span="24">
            <el-form-item :label="autoPrefix('9920680')">
              <el-input
                v-model="form.description"
                type="textarea"
                :rows="4"
                maxlength="50"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="submit-btn" @click="onSubmit">{{ $t('6dacffe') }}</div>
      </el-form>
    </Box>

    <Box v-loading="loading" :title="autoPrefix('2c299e4')">
      <div class="edu-list">
        <div v-for="item in detail.others" :key="item.id" class="edu-item">
          <div
            class="cover"
            :style="{ backgroundImage: `url(${item.picture})` }"
          ></div>
          <div class="title">{{ item.title | textFilter }}</div>
          <ul class="desc">
            <li>
              <i class="el-icon-time"></i>
              <span>{{ $t('ff45356') }}：{{ item.date }} {{ item.startTime }}-{{
                item.endTime
              }}</span>
            </li>
            <li>
              <i class="el-icon-location-outline"></i>
              <span>{{ $t('6a7552b') }}：{{ item.address | textFilter }}</span>
            </li>
            <li>
              <i class="el-icon-user"></i>
              <span>{{ $t('e192617') }}：{{ item.organizer | textFilter }}</span>
            </li>
          </ul>
          <el-button class="btn" @click="jump(item.id)">{{ $t('ad6ab8a') }}</el-button>
        </div>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Api from '@/api/communication/activity'
import Breadcrumb from '@/components/Breadcrumb'

const nav = [
  { name: 'bae89f7', path: '/communication' },
  { name: '5680a38', path: '/communication/activity' }
]
export default {
  components: {
    Box,
    Breadcrumb,
  },
  data() {
    return {
      loading: false,
      detail: {},
      form: {},
      id: '',
      nav: [],
      rules: {
        name: [
          { required: true, message: this.$t('97ea28f'), trigger: 'blur' },
          { min: 1, max: 20, message: this.$t('97ea28f'), trigger: 'blur' }
        ],
        phone: [
          { required: true, message: this.$t('89a6994'), trigger: 'blur' },
          { min: 7, max: 20, message: this.$t('89a6994') }
        ],
      }
    }
  },
  watch: {
    '$route.query.id'() {
      this.getDetail()
    },
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    jump(id) {
      this.$router.push({
        path: '/communication/activity/detail',
        query: { id },
      })
    },
    autoPrefix(name) {
      let fun = '$t'
      if (this.abroad) {
        fun = 'geEn'
      }
      return this[fun](name)
    },
    getDetail() {
      this.id = this.$route.query.id
      this.loading = true
      Api.getDetail(this.id, this.form)
        .then((res) => {
          this.detail = res
          this.nav = [
            ...nav,
            {
              name: res.title,
              path: ''
            }
          ]
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    onSubmit() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          this.submitApi()
        } else {
          return false
        }
      })
    },
    submitApi() {
      this.loading = true
      Api.submit(this.id, this.form)
        .then((res) => {
          if (res.code === "000000") {
            this.$message('报名成功')
          } else {
            // 报名未成功接口也是200
            this.$message(res.message)
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    }
  },
}
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  margin: 0 auto 20px;
}

.pane {
  &-header {
    // height: 80px;
    padding: 25px 30px;
    border-bottom: 1px solid #eee;
    font-size: 20px;
    color: #333333;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }

  &-body {
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 60px;
    img {
      max-width: 100%;
    }
  }

  &-group {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 10px;
    }

    .title {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 20px;
    }

    .desc {
      padding-left: 20px;
      font-size: 14px;
      color: #666666;
      line-height: 24px;

      em {
        font-size: 14px;
        color: #333333;
        margin: 0 3px;
      }
    }
  }
}

.edu-list {
  // overflow: hidden;
  display: flex;

  .edu-item {
    box-sizing: border-box;
    margin-left: 20px;
    width: calc(33.3333% - (40px / 3));
    min-width: 0;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px rgba(2, 24, 36, 0.04);
    border: 1px solid #e5e8ed;
    background: #ffffff;

    &:first-child {
      margin-left: 0;
    }

    .cover {
      height: 200px;
      border-radius: 4px;
      background: #efefef no-repeat center;
      background-size: cover;
      margin-bottom: 40px;
    }

    .title {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 20px;
      margin-bottom: 24px;
    }

    .desc {
      li {
        padding-bottom: 16px;
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }

      i {
        font-size: 16px;
        margin-right: 10px;
        color: #c3c3c3;
      }
    }

    .btn {
      display: block;
      margin: 20px auto;
      min-width: 120px;
      height: 40px;
      border-color: #00a4ff;
      color: #00a4ff;
    }
  }
}
.submit-btn {
  width: 120px;
  height: 32px;
  line-height: 32px;
  background: #1890FF;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-left: 82px;
}
.el-input {
  width: 240px;
}
.el-col {
  &-8 {
    display: flex;
    justify-content: flex-end;
  }
  &.fix-first {
    justify-content: flex-start;
  }
}
.fix-row {
  .el-col, .el-form-item {
    width: 100%;
  }
  /deep/ .el-form-item__content {
    width: calc(100% - 82px);
  }
}

</style>
